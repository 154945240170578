html, body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  /*background-color: #fff;*/
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

div#notebook-header {
    z-index: 1000;
}

div#editor-react-root{
    /*needed to ensure that the div grows to full height*/
  flex-grow: 1;
  display: flex;
  overflow: hidden; /* needed to ensure the contents are restriced in size */
}

iframe#eval-frame {
    border: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
