.lm_content {
     background: unset;
     border: unset;
}

.lm_goldenlayout {
    background: unset;
}

/*.lm_items {
    border: 1px solid #cbcbcb;
}*/
