
.medium-menu {
  display: flex !important;
  justify-content: space-between;
  flex-flow: row wrap;
  max-height:32px !important;
}
.editor-icon-menu {
  transition: 50ms;
}
.editor-icon-menu:hover:after {
  content: '▼';
  font-size:10px;
  font-weight:300;
  transform: scale(1, .5);
  position: absolute;
  bottom: 5px;
  width: 32px;
  text-align:center;
  display: block;
}

.iodide-menu-item {
  max-height:32px !important;
  line-height:32px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.iodide-menu-divider {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}

.secondary-menu-item {
  text-align:right;
  padding:0px !important;
  padding-left:16px !important;
}

.medium-menu div div {
  display: flex !important;
  float: none !important;
  justify-content: space-between;
  flex-flow: row wrap;
}

.medium-menu div div div:first-child {
  order: 1;
  width: 20px;
  justify-content: flex-end;
  font-size: 12px;

  display: block;
}

.menu-button {
  color: #fafafa;
  width: 36px !important;
  height:36px !important;
  padding: 0 !important;
}

.menu-button:hover {
  outline: 1px solid #fafafa
}

.view-mode-toggle-from-presentation {
  position: fixed;
  right: 20px;
  top: 10px;
  z-index:100;
}

.user-avatar {
  height: 24px;
  border-radius: 50%;
}

div.display-buttons {
  /* left: 280px; */
  margin:0;
  padding:0;
  z-index: 3;
  height: 50px;
  width: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
 
button.display-button {
  margin:0;
  padding:2px;
  border: none;
  display: block;
  width: 25px;
  height: 25px;
  text-align:center;
  color:white;
  background-color: darkgray;
}
 
button.display-button.is-active {
  background-color: #D3D3D3 ;
  /* background-color: rgb(30,30,30); */
}
 
button.display-button:focus {
  outline: none;
}
 
button.display-button:hover {
  background-color: #686868;
}

/************************************************/
/* et cetera styles */

div.iodide-tooltip {
  font-size: 0.900rem;
}
