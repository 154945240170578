html, body {
  font-family: 'Open Sans', sans-serif;
  
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  /*overflow: hidden;*/
  position: relative;
}

div#eval-container {
  /*height: 100vh;*/
  width: 100%;
  /*display: flex;*/
  /*flex-direction: column;*/
}

div#eval-container.presentation-mode {
  display: block;
  overflow-y: auto;
}

.default-rep-button {
  position: absolute;
  right: 0;
  top: 0;
  word-break: keep-all;
}

/************************************************/
/* cell output styles */

.data-set-info {
  color: #888;
  font-style: italic;
  font-size: 12px;
  padding-bottom: 4px;
}

div.error-output {
background: #fff1f4;
color: #a41c1c;
overflow-x: auto;
}

.string-rep {
    word-break: break-word;
    color: #c41a16;
}
.string-rep-before-quote::before {
    content: '"';
    color: #555;
}
.string-rep-after-quote::after {
    content: '"';
    color: #555;
}

.string-rep-ellipsis {
    color: #555;
}

.number-rep {
    color: rgb(28, 0, 207);
}

.date-rep-small {
    word-break: normal;
}

.elements-omitted-info-rep {
    font-style: italic;
    background-color: #fafafa;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 0px 3px;
    white-space: nowrap;
}

div.promise-handler-state {
  display:flex;
  font-size:12px;
  padding: 5px;
  width: 180px;
  background-color:#f9f9f9;
  border: 1px solid #f1f1f1;
  margin-top:2px;
}

div.promise-handler-label {
  width: 80px;
  padding-left:5px;
  font-weight:900;
  text-transform:uppercase;
}

div.promise-handler-status {
  width:100px;
  padding-left:5px;
  text-align:center;
  font-style:italic;
  color: rgb(40,40,40)
}

div.promise-handler-value {
  margin-top:6px;
}

/************************************************/
/* et cetera styles */

div.iodide-tooltip {
  font-size: 0.900rem;
}

code {
  padding: 0.2em 0.4em;
  margin: 0;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}

button.display-button {
  margin:0;
  padding:2px;
  border: none;
  display: block;
  width: 25px;
  height: 25px;
  text-align:center;
  color:white;
  background-color: darkgray;
}
 
button.display-button.is-active {
  background-color: #D3D3D3 ;
  /* background-color: rgb(30,30,30); */
}
 
button.display-button:focus {
  outline: none;
}
 
button.display-button:hover {
  background-color: #686868;
}
